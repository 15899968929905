<template>
  <ApDialog
    :value="true"
    title="Send a Message"
    :actions="dialogActions"
    @cancel="$emit('cancel')"
    @send="sendSlackMessage"
  >
    <template #content>
      <div class="px-6">
        <v-row dense>
          <v-col cols="12" sm="8">
            <v-select
              v-model="purpose"
              append-icon="$mdi-chevron-down"
              outlined
              dense
              hide-details
              :items="purposeItems"
              item-value="value"
              item-text="value"
              placeholder="Purpose"
            />
          </v-col>

          <v-col cols="12" sm="4">
            <v-select
              v-model="priority"
              append-icon="$mdi-chevron-down"
              outlined
              dense
              hide-details
              :items="priorityItems"
              item-value="value"
              item-text="value"
              placeholder="Priority"
            />
          </v-col>
        </v-row>

        <v-textarea
          v-model="message"
          no-resize
          flat
          solo
          outlined
          label="Write your message"
          class="mt-2"
        />
      </div>
    </template>
  </ApDialog>
</template>

<script>
import ApDialog from '@/components/common/ApDialog'

export default {
  components: {
    ApDialog,
  },
  props: {
    isSendingMessage: {
      type: Boolean,
      deafult: false,
    },
  },
  data() {
    return {
      message: undefined,
      priority: undefined,
      purpose: undefined,
      purposeItems: [
        { value: 'Question', selected: true },
        { value: 'Help solving a duplicate' },
        { value: 'Error' },
        { value: 'Feedback' },
      ],
      priorityItems: [
        { value: 'High' },
        { value: 'Medium', selected: true },
        { value: 'Low' },
      ],
    }
  },
  computed: {
    dialogActions() {
      return [
        {
          event: 'cancel',
          label: 'Cancel',
          outlined: true,
          color: 'primary',
        },
        {
          event: 'send',
          label: 'Send',
          color: 'primary',
          rightIcon: '$mdi-send-outline',
          loading: this.isSendingMessage,
        },
      ]
    },
  },
  methods: {
    async sendSlackMessage() {
      this.$emit('slack-message', {
        message: this.message,
        priority: this.priority,
        purpose: this.purpose,
      })
    },
  },
}
</script>
