var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"dupegrid_grid"}},[_c('v-data-table',{ref:"table",attrs:{"dense":"","fixed-header":"","hide-default-header":"","hide-default-footer":"","disable-pagination":"","headers":_vm.columnsProcessed,"items":_vm.rows},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('thead',[_c('VueDraggable',{attrs:{"tag":"tr","draggable":".is-draggable"},on:{"change":_vm.onColumnDragged,"end":_vm.onDragEnd,"start":_vm.onDragStart},model:{value:(props.headers),callback:function ($$v) {_vm.$set(props, "headers", $$v)},expression:"props.headers"}},_vm._l((props.headers),function(header){return _c('th',{key:((header.id) + "-*-" + (header.name)),ref:((header.id) + "-*-" + (header.name)),refInFor:true,staticClass:"text-caption text-left py-1 px-3",class:{
              'is-sticky': header.isSticky,
              'is-draggable': !header.isSticky,
              'is-primary': header.isPrimary,
            },style:({
              'min-width': header.width,
              width: _vm.isActionsColumn(header) ? '80px' : undefined,
            }),attrs:{"data-tt":header.tutorialCode}},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[(header.icon)?_c('ApTooltip',{attrs:{"caption":header.tooltip}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":header.iconColor,"small":""}},[_vm._v(" "+_vm._s(header.icon)+" ")]),_vm._v(" "+_vm._s(header.label)+" "),(header.isRequired)?_c('span',{staticClass:"ml-1",attrs:{"data-tt":"mandatoryField"}},[_vm._v("*")]):_vm._e()],1)]):_c('span',[_vm._v(" "+_vm._s(header.label)+" "),(header.isRequired)?_c('span',{staticClass:"ml-1",attrs:{"data-tt":"mandatoryField"}},[_vm._v("*")]):_vm._e()]),(!header.isSticky)?_c('v-icon',{staticClass:"ml-3",class:{
                  'transparent--text': !props.options.sortBy.includes(
                    header.name
                  ),
                },attrs:{"small":""},on:{"click":function($event){return on.sort(header.name)}}},[_vm._v(" "+_vm._s(props.options.sortBy.includes(header.name) && props.options.sortDesc.includes(true) ? '$mdi-sort-alphabetical-descending' : '$mdi-sort-alphabetical-ascending')+" ")]):_vm._e()],1)])}),0)],1)]}},{key:"item",fn:function(ref){
                var row = ref.item;
return [_c('tr',{key:row.id},[_vm._l((_vm.columnsProcessed),function(column,index){
                var _obj;
return [_c('td',{key:((row.id) + "-*-" + index),staticClass:"px-3",class:( _obj = {
              'is-sticky': column.isSticky,
              'is-last-sticky': column.isLastSticky
            }, _obj[column.cellClasses && column.cellClasses.join(' ')] = !!column.cellClasses, _obj['is-primary-record'] = 
                row.columns[column.id] && row.columns[column.id].isSelected, _obj['is-disabled'] =  !row.isMergable && !_vm.isActionsColumn(column), _obj )},[(_vm.isActionsColumn(column))?_c('div',{staticClass:"d-flex align-center justify-end"},[(!row.isPrimaryRecordSelected)?_c('v-menu',{attrs:{"close-on-content-click":"","offset-y":"","max-width":"280px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-row',{staticClass:"fill-height",attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('v-btn',_vm._g({attrs:{"data-tt":"excludeButton","icon":"","small":""}},on),[_c('v-icon',{attrs:{"size":"16","color":"ap-dark-gray"}},[_vm._v(" $mdi-cancel ")])],1)],1)]}}],null,true)},[_c('v-card',{staticClass:"text-caption"},[_c('div',{staticClass:"py-2 px-4"},[_c('div',{staticClass:"text-body-2 font-weight-bold ap-dark-gray--text"},[_vm._v(" Is this record a duplicate? ")]),_c('div',[_c('v-radio-group',{staticClass:"my-2",attrs:{"hide-details":"","value":_vm.initialValue(row),"disabled":!_vm.isEditable},on:{"change":function($event){return _vm.$emit('action', { item: row, action: $event })}}},_vm._l((_vm.actions),function(action){return _c('v-radio',{key:action.type,attrs:{"value":action.type},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"text-caption ap-dark-gray--text"},[_vm._v(" "+_vm._s(action.label)+" ")])]},proxy:true}],null,true)})}),1)],1)])])],1):_vm._e(),_c('ApTooltip',{attrs:{"caption":"Open Details page"}},[_c('v-btn',{attrs:{"href":row.link,"target":"_blank","icon":"","small":"","data-tt":"openDetailsPage"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" $mdi-open-in-new ")])],1)],1)],1):(column.name === 'MCS')?_c('div',[_c('DupeGridHeaderMatchConfidenceScore',{attrs:{"is-only-icon":"","value":row.mcs_category}})],1):(
                (column.isPrimary && row[column.name]) ||
                (!column.isPrimary && column.isSelectable)
              )?_c('v-radio-group',{staticClass:"mt-0 pt-0",attrs:{"disabled":!_vm.isEditable,"hide-details":""},on:{"change":function($event){return _vm.onCellSelected(column, row)}},model:{value:(row.columns[column.id].isSelected),callback:function ($$v) {_vm.$set(row.columns[column.id], "isSelected", $$v)},expression:"row.columns[column.id].isSelected"}},[_c('v-radio',{attrs:{"ripple":false,"value":true,"disabled":!row.isMergable},scopedSlots:_vm._u([{key:"label",fn:function(){
              var _obj;
return [_c('div',{staticClass:"text-body-2 text-truncate",class:( _obj = {}, _obj[column.cellClasses && column.cellClasses.join(' ')] = !!column.cellClasses, _obj )},[_c('ApTooltip',{attrs:{"caption":row[column.name]}},[_vm._v(" "+_vm._s(row[column.name])+" ")])],1)]},proxy:true}],null,true)})],1):_c('ApTooltip',{attrs:{"caption":row[column.name]}},[_vm._v(" "+_vm._s(row[column.name])+" ")])],1)]})],2)]}},{key:"foot",fn:function(){return [(_vm.finalData)?_c('tfoot',[_c('tr',{attrs:{"data-tt":"finalRecord"}},[_vm._l((_vm.columnsProcessed),function(column,index){return [(!column.isPrimary)?_c('td',{key:index,staticClass:"px-3",class:{
                'font-weight-black primary--text':
                  !_vm.isActionsColumn(column) &&
                  !_vm.isFinalCellEditable(column.name),
                'font-weight-medium text-right ap-dark-gray--text':
                  _vm.isActionsColumn(column),
                'is-sticky': column.isSticky,
                'is-last-sticky': column.isLastSticky,
                'py-1': _vm.isFinalCellEditable(column.name),
                'ap-red-10':
                  column.isRequired &&
                  !_vm.finalDataValue(column.name) &&
                  _vm.isMandatoryFieldsHighlighted,
              },attrs:{"data-tt":_vm.isFinalCellEditable(column.name) ? 'editableCell' : undefined,"colspan":_vm.isActionsColumn(column)
                  ? _vm.columnsProcessed.filter(function (el) { return el.isPrimary; }).length + 1
                  : 1}},[(_vm.isFinalCellEditable(column.name))?_c('v-text-field',{staticClass:"ma-0 pa-0 text-body-2",class:{
                  'is-edit-icon':
                    !!_vm.finalDataValue(column.name) || !_vm.isEditable,
                },staticStyle:{"min-width":"160px"},attrs:{"value":_vm.finalDataValue(column.name),"disabled":!_vm.isEditable,"placeholder":_vm.isEditable ? 'Add ' + column.label : '',"hide-details":"","full-width":"","prepend-inner-icon":_vm.finalDataValue(column.name) || !_vm.isEditable
                    ? '$mdi-pencil'
                    : '$mdi-plus'},on:{"change":function($event){return _vm.onFinalCellChanged(column, $event)}}}):_c('span',[_vm._v(" "+_vm._s(_vm.finalDataValue(column.name))+" ")])],1):_vm._e()]})],2)]):_vm._e()]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }