<template>
  <div class="d-flex align-center justify-space-between">
    <div class="d-flex flex-wrap" data-tt="metadata">
      <div
        v-for="(item, index) in details"
        :key="index"
        class="d-flex text-caption py-1"
      >
        <ApCaptionIcon
          v-if="item.icon && item.caption"
          :icon="item.icon"
          :tooltip="item.caption"
          data-tt="cacheIndicator"
        />

        <template v-else>
          <span class="font-weight-medium mr-1">{{ item.label }}</span>
          {{ item.value }}
        </template>

        <v-divider v-if="index < details.length - 1" vertical class="mx-2" />
      </div>
    </div>

    <div class="d-flex align-center">
      <v-btn
        v-if="isUndoVisible"
        small
        outlined
        class="ml-2"
        color="ap-dark-gray"
        @click="$emit('undo')"
      >
        <v-icon left> $mdi-undo </v-icon>
        Undo
      </v-btn>

      <v-btn
        v-if="isMergeVisible"
        small
        depressed
        color="success"
        class="ml-2"
        data-tt="mergeButton"
        @click="$emit('merge')"
      >
        Merge
        <v-icon right> $mdi-call-merge </v-icon>
      </v-btn>

      <v-btn
        v-if="isSendToAdminVisible"
        small
        color="success"
        depressed
        class="ml-2"
        data-tt="sendToAdmin"
        :disabled="isSendToAdminDisabled"
        @click="$emit('send-to-admin')"
      >
        {{ isSendToAdminDisabled ? 'Sent to Admin' : 'Send to Admin' }}
        <v-icon right>
          {{ isSendToAdminDisabled ? '$mdi-check' : '$mdi-send-outline' }}
        </v-icon>
      </v-btn>

      <v-btn
        v-if="isReprocessButtonVisible"
        small
        color="success"
        depressed
        class="ml-2"
        @click="$emit('reprocess')"
      >
        Reprocess
        <v-icon right> $mdi-repeat </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import ApCaptionIcon from '@/components/common/ApCaptionIcon'

export default {
  components: {
    ApCaptionIcon,
  },
  props: {
    details: {
      type: Array,
      default: () => [],
    },
    isMergeVisible: {
      type: Boolean,
      default: false,
    },
    isUndoVisible: {
      type: Boolean,
      default: false,
    },
    isSendToAdminVisible: {
      type: Boolean,
      default: false,
    },
    isSendToAdminDisabled: {
      type: Boolean,
      default: false,
    },
    isReprocessButtonVisible: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
