<template>
  <div id="dupegrid_grid">
    <v-data-table
      ref="table"
      dense
      fixed-header
      hide-default-header
      hide-default-footer
      disable-pagination
      :headers="columnsProcessed"
      :items="rows"
    >
      <template #header="{ props, on }">
        <thead>
          <VueDraggable
            v-model="props.headers"
            tag="tr"
            draggable=".is-draggable"
            @change="onColumnDragged"
            @end="onDragEnd"
            @start="onDragStart"
          >
            <th
              v-for="header in props.headers"
              :key="`${header.id}-*-${header.name}`"
              :ref="`${header.id}-*-${header.name}`"
              class="text-caption text-left py-1 px-3"
              :class="{
                'is-sticky': header.isSticky,
                'is-draggable': !header.isSticky,
                'is-primary': header.isPrimary,
              }"
              :style="{
                'min-width': header.width,
                width: isActionsColumn(header) ? '80px' : undefined,
              }"
              :data-tt="header.tutorialCode"
            >
              <div class="d-flex align-center justify-space-between">
                <ApTooltip v-if="header.icon" :caption="header.tooltip">
                  <div class="d-flex align-center">
                    <v-icon :color="header.iconColor" small class="mr-1">
                      {{ header.icon }}
                    </v-icon>

                    {{ header.label }}
                    <span
                      v-if="header.isRequired"
                      class="ml-1"
                      data-tt="mandatoryField"
                      >*</span
                    >
                  </div>
                </ApTooltip>

                <span v-else>
                  {{ header.label }}
                  <span
                    v-if="header.isRequired"
                    class="ml-1"
                    data-tt="mandatoryField"
                    >*</span
                  >
                </span>

                <v-icon
                  v-if="!header.isSticky"
                  small
                  class="ml-3"
                  :class="{
                    'transparent--text': !props.options.sortBy.includes(
                      header.name
                    ),
                  }"
                  @click="on.sort(header.name)"
                >
                  {{
                    props.options.sortBy.includes(header.name) &&
                    props.options.sortDesc.includes(true)
                      ? '$mdi-sort-alphabetical-descending'
                      : '$mdi-sort-alphabetical-ascending'
                  }}
                </v-icon>
              </div>
            </th>
          </VueDraggable>
        </thead>
      </template>

      <template #item="{ item: row }">
        <tr :key="row.id">
          <template v-for="(column, index) in columnsProcessed">
            <td
              :key="`${row.id}-*-${index}`"
              class="px-3"
              :class="{
                'is-sticky': column.isSticky,
                'is-last-sticky': column.isLastSticky,
                [column.cellClasses && column.cellClasses.join(' ')]:
                  !!column.cellClasses,
                'is-primary-record':
                  row.columns[column.id] && row.columns[column.id].isSelected,
                'is-disabled': !row.isMergable && !isActionsColumn(column),
              }"
            >
              <div
                v-if="isActionsColumn(column)"
                class="d-flex align-center justify-end"
              >
                <v-menu
                  v-if="!row.isPrimaryRecordSelected"
                  close-on-content-click
                  offset-y
                  max-width="280px"
                >
                  <template #activator="{ on }">
                    <v-row
                      no-gutters
                      align="center"
                      justify="center"
                      class="fill-height"
                    >
                      <v-btn data-tt="excludeButton" icon small v-on="on">
                        <v-icon size="16" color="ap-dark-gray">
                          $mdi-cancel
                        </v-icon>
                      </v-btn>
                    </v-row>
                  </template>

                  <v-card class="text-caption">
                    <div class="py-2 px-4">
                      <div
                        class="text-body-2 font-weight-bold ap-dark-gray--text"
                      >
                        Is this record a duplicate?
                      </div>

                      <div>
                        <v-radio-group
                          class="my-2"
                          hide-details
                          :value="initialValue(row)"
                          :disabled="!isEditable"
                          @change="
                            $emit('action', { item: row, action: $event })
                          "
                        >
                          <v-radio
                            v-for="action in actions"
                            :key="action.type"
                            :value="action.type"
                          >
                            <template #label>
                              <span class="text-caption ap-dark-gray--text">
                                {{ action.label }}
                              </span>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </div>
                    </div>
                  </v-card>
                </v-menu>

                <ApTooltip caption="Open Details page">
                  <v-btn
                    :href="row.link"
                    target="_blank"
                    icon
                    small
                    data-tt="openDetailsPage"
                  >
                    <v-icon small> $mdi-open-in-new </v-icon>
                  </v-btn>
                </ApTooltip>
              </div>

              <div v-else-if="column.name === 'MCS'">
                <DupeGridHeaderMatchConfidenceScore
                  is-only-icon
                  :value="row.mcs_category"
                />
              </div>

              <v-radio-group
                v-else-if="
                  (column.isPrimary && row[column.name]) ||
                  (!column.isPrimary && column.isSelectable)
                "
                v-model="row.columns[column.id].isSelected"
                :disabled="!isEditable"
                hide-details
                class="mt-0 pt-0"
                @change="onCellSelected(column, row)"
              >
                <v-radio
                  :ripple="false"
                  :value="true"
                  :disabled="!row.isMergable"
                >
                  <template #label>
                    <div
                      class="text-body-2 text-truncate"
                      :class="{
                        [column.cellClasses && column.cellClasses.join(' ')]:
                          !!column.cellClasses,
                      }"
                    >
                      <ApTooltip :caption="row[column.name]">
                        {{ row[column.name] }}
                      </ApTooltip>
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>

              <ApTooltip v-else :caption="row[column.name]">
                {{ row[column.name] }}
              </ApTooltip>
            </td>
          </template>
        </tr>
      </template>

      <template #foot>
        <tfoot v-if="finalData">
          <tr data-tt="finalRecord">
            <template v-for="(column, index) in columnsProcessed">
              <td
                v-if="!column.isPrimary"
                :key="index"
                class="px-3"
                :data-tt="
                  isFinalCellEditable(column.name) ? 'editableCell' : undefined
                "
                :class="{
                  'font-weight-black primary--text':
                    !isActionsColumn(column) &&
                    !isFinalCellEditable(column.name),
                  'font-weight-medium text-right ap-dark-gray--text':
                    isActionsColumn(column),
                  'is-sticky': column.isSticky,
                  'is-last-sticky': column.isLastSticky,
                  'py-1': isFinalCellEditable(column.name),
                  'ap-red-10':
                    column.isRequired &&
                    !finalDataValue(column.name) &&
                    isMandatoryFieldsHighlighted,
                }"
                :colspan="
                  isActionsColumn(column)
                    ? columnsProcessed.filter((el) => el.isPrimary).length + 1
                    : 1
                "
              >
                <v-text-field
                  v-if="isFinalCellEditable(column.name)"
                  :value="finalDataValue(column.name)"
                  :disabled="!isEditable"
                  :placeholder="isEditable ? 'Add ' + column.label : ''"
                  class="ma-0 pa-0 text-body-2"
                  hide-details
                  full-width
                  style="min-width: 160px"
                  :class="{
                    'is-edit-icon':
                      !!finalDataValue(column.name) || !isEditable,
                  }"
                  :prepend-inner-icon="
                    finalDataValue(column.name) || !isEditable
                      ? '$mdi-pencil'
                      : '$mdi-plus'
                  "
                  @change="onFinalCellChanged(column, $event)"
                />

                <span v-else>
                  {{ finalDataValue(column.name) }}
                </span>
              </td>
            </template>
          </tr>
        </tfoot>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ApTooltip from '@/components/common/ApTooltip'
import VueDraggable from 'vuedraggable'
import DupeGridHeaderMatchConfidenceScore from './DupeGridHeaderMatchConfidenceScore'

export default {
  components: {
    VueDraggable,
    ApTooltip,
    DupeGridHeaderMatchConfidenceScore,
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    columnsConfigs: {
      type: Object,
      default: () => {},
    },
    rows: {
      type: Array,
      required: true,
    },
    finalData: {
      type: Object,
      default: undefined,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    isMandatoryFieldsHighlighted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      actions: [
        {
          type: 'merge',
          label: 'Yes. Merge record.',
        },
        {
          type: 'dupe_set',
          label: 'Do not merge it this time.',
        },
        {
          type: 'never',
          label: 'No. Never merge record with the others in this set.',
        },
      ],
    }
  },
  computed: {
    columnsProcessed() {
      const columnsClone = [...this.columns]
      const lastStickyColumnIndex = [...this.columns]
        .reverse()
        .findIndex((el) => el.isSticky)

      if (lastStickyColumnIndex > -1) {
        columnsClone[
          columnsClone.length - lastStickyColumnIndex - 1
        ].isLastSticky = true
      }

      return columnsClone.map((el) => {
        return {
          ...el,
          ...(this.columnsConfigs?.[el.status] || {}),
        }
      })
    },
  },
  mounted() {
    // the setTimeout is needed.
    // Otherwise we get the wrong columns width
    setTimeout(() => {
      this.setStickyColumns()
      this.addColumnResizingEvents()
    }, 200)
  },
  methods: {
    setStickyColumns() {
      const rows = document.querySelectorAll('tr')
      rows.forEach((row) => {
        const cells = [...row.querySelectorAll('.is-sticky')]
        cells.forEach((stickyColumn, index) => {
          const leftPx = cells
            .slice(0, index)
            .reduce((acc, cell) => acc + cell.getBoundingClientRect().width, 0)

          stickyColumn.style.left = `${leftPx}px`
        })
      })
    },
    addColumnResizingEvents() {
      let currentColumn
      let startOffset
      const tableHeaders = this.$refs.table.$el.querySelectorAll('th')

      tableHeaders.forEach((th) => {
        const thClasses = th.classList
        if (thClasses.contains('is-sticky')) {
          return false
        }

        const grip = document.createElement('div')
        grip.classList.add('resize-grip')

        grip.addEventListener('mousedown', (e) => {
          e.preventDefault()
          e.stopPropagation()
          currentColumn = th
          startOffset = th.offsetWidth - e.pageX
        })

        th.appendChild(grip)
      })

      document.addEventListener('mouseup', (e) => {
        if (currentColumn) {
          e.preventDefault()
          e.stopPropagation()
          currentColumn = undefined

          const columns = this.columns.map((column) => {
            if (!column.isSticky) {
              const width =
                this.$refs[`${column.id}-*-${column.name}`][0].offsetWidth
              return {
                ...column,
                width: `${width}px`,
              }
            }

            return column
          })

          this.$emit('column-moved', columns)
        }
      })

      document.addEventListener('mousemove', (e) => {
        if (currentColumn) {
          const width = startOffset + e.pageX
          currentColumn.style.minWidth = width > 600 ? '600px' : `${width}px`
        }
      })
    },
    onColumnDragged(dragEvent) {
      const fromIndex = dragEvent.moved.oldIndex
      const toIndex = dragEvent.moved.newIndex
      let columns = [...this.columns]

      // move column
      var [columnToMove] = columns.splice(fromIndex, 1)
      columns.splice(toIndex, 0, columnToMove)

      this.$emit('column-moved', columns)
    },
    onDragStart(e) {
      e.target.classList.add('is-grabbing')
    },
    onDragEnd(e) {
      e.target.classList.remove('is-grabbing')
    },
    initialValue(item) {
      return this.actions.find((action) => action.type === item.action).type
    },
    isFinalCellEditable(columnName) {
      return this.finalData[columnName]?.isEditable
    },
    isActionsColumn(column) {
      return column.name === 'actions'
    },
    finalDataValue(columnName) {
      return this.finalData[columnName]?.value
    },
    onCellSelected(column, row) {
      const { oldRowId, oldValue } = this.getColumnCurrentStatus(column)

      this.$emit('cell-selected', {
        columnId: column.id,
        newRowId: row.id,
        oldRowId,
        newValue: row[column.name],
        oldValue,
      })
    },
    onFinalCellChanged(column, newValue) {
      const { oldRowId, oldValue } = this.getColumnCurrentStatus(column)

      this.$emit('final-cell-changed', {
        columnId: column.id,
        newValue,
        oldRowId,
        oldValue,
      })
    },
    getColumnCurrentStatus(column) {
      let oldValue = null
      let oldRowId = null

      for (const el of this.rows) {
        if (el.columns[column.id] && el.columns[column.id].isSelected) {
          oldRowId = el.id
          oldValue = el[column.name]
        }
      }

      return {
        oldRowId,
        oldValue,
      }
    },
  },
}
</script>

<style scoped>
::v-deep .v-data-table__wrapper {
  max-height: 400px;
}

tfoot {
  position: sticky;
  bottom: 0;
  z-index: 3;
}

thead th {
  border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  background: var(--v-ap-light-grey-base) !important;
  white-space: nowrap;
  position: relative;
}

thead th:not(:last-child),
tfoot td:not(:last-child) {
  border-right: 1px solid lightgrey !important;
}

thead th:hover .transparent--text {
  color: var(--v-ap-dark-gray-base) !important;
  opacity: 0.25;
}

tfoot td,
tbody td {
  border-bottom: none !important;
  background: white !important;
  max-width: 600px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default;
}

.is-sticky {
  position: sticky;
  z-index: 3 !important;
}

th.is-sticky {
  z-index: 4 !important;
}

.is-last-sticky {
  border-right: 1px solid lightgrey !important;
}

.is-primary-record {
  background-color: var(--v-ap-blue-10-base) !important;
}

.is-draggable {
  cursor: grab;
}

.is-grabbing * {
  cursor: grabbing !important;
}

.is-disabled {
  background-image: linear-gradient(
    135deg,
    #b9b9b9 16.67%,
    #ffffff 16.67%,
    #ffffff 50%,
    #b9b9b9 50%,
    #b9b9b9 66.67%,
    #ffffff 66.67%,
    #ffffff 100%
  ) !important;
  background-size: 4.24px 4.24px !important;
}

.resize-grip {
  content: '&nbsp;';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 5px;
  cursor: col-resize;
}

.v-radio .v-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

::v-deep .v-input--selection-controls__input {
  width: 20px;
  height: 20px;
}
::v-deep .v-input__prepend-inner {
  margin-top: 4px;
  /* 8px; */
}
::v-deep .v-text-field.is-edit-icon .v-icon__svg {
  width: 16px;
  height: 16px;
}
::v-deep .v-text-field .v-icon__svg {
  width: 20px;
  height: 20px;
}
::v-deep .v-input__slot {
  min-height: 30px !important;
}
</style>
