import { render, staticRenderFns } from "./ApAlert.vue?vue&type=template&id=3276cc8b&scoped=true&"
import script from "./ApAlert.vue?vue&type=script&lang=js&"
export * from "./ApAlert.vue?vue&type=script&lang=js&"
import style0 from "./ApAlert.vue?vue&type=style&index=0&id=3276cc8b&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3276cc8b",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VCol,VIcon,VRow})
