<template>
  <v-alert :style="{ 'background-color': configs.backgroundColor }">
    <v-row no-gutters align="center">
      <!-- Icon/Image -->
      <v-col cols="auto" class="pr-4">
        <img
          v-if="configs.image"
          height="36"
          :src="configs.image"
          :alt="configs.imageAlt"
        />
        <v-icon v-else-if="configs.icon" :color="configs.textColor">
          {{ configs.icon }}
        </v-icon>
      </v-col>

      <!-- Texts -->
      <v-col class="pb-4 pb-sm-0 pr-4">
        <div class="font-weight-black" :style="{ color: configs.textColor }">
          {{ title }}
        </div>
        <div class="text-body-2 ap-dark-gray--text">
          <slot name="text">
            <div>{{ text }}</div>
          </slot>
        </div>
      </v-col>

      <!-- Rating -->
      <v-col
        v-if="isRatingVisible"
        cols="12"
        sm="3"
        class="rating-container pl-sm-4 pt-4 pt-sm-0"
      >
        <div class="text-body-2 primary--text">
          {{ ratingLabel }}
        </div>
        <ApRating @input="$emit('rating-changed', $event)" />
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
const TYPES = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
}

export default {
  components: {
    ApRating: () => import('./ApRating.vue'),
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      required: true,
      validator(value) {
        return [TYPES.SUCCESS, TYPES.WARNING, TYPES.ERROR].includes(value)
      },
    },
    isRatingVisible: {
      type: Boolean,
      default: false,
    },
    ratingLabel: {
      type: String,
      default: 'Rate this result',
    },
  },
  computed: {
    configs() {
      switch (this.type) {
        case TYPES.SUCCESS:
          return {
            icon: '$mdi-check-circle-outline',
            textColor: '#54a15e',
            backgroundColor: 'rgba(84,161,94,0.1)',
          }
        case TYPES.WARNING:
          return {
            image: require('@/assets/images/alert-warning.png'),
            imageAlt: 'Warning image',
            textColor: '#f57600',
            backgroundColor: 'rgba(245,118,0,0.1)',
          }
        case TYPES.ERROR:
        default:
          return {
            icon: '$mdi-alert-circle',
            textColor: '#D50000',
            backgroundColor: 'rgba(213,0,0,0.1)',
          }
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/settings/_variables';

.rating-container {
  border-top: 1px solid var(--v-ap-grey-base);

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    border-left: 1px solid var(--v-ap-grey-base);
    border-top: none;
  }
}
</style>
