<template>
  <div class="d-flex align-center">
    <ApTooltip :caption="tooltip">
      <div class="d-flex align-center text-caption">
        <v-icon small color="ap-icon-gray">
          {{ icon }}
        </v-icon>
        <span v-if="label" class="ml-1">{{ label }}</span>
      </div>
    </ApTooltip>
  </div>
</template>

<script>
import ApTooltip from './ApTooltip.vue'

export default {
  components: {
    ApTooltip,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      required: true,
    },
  },
}
</script>
