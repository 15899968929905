<template>
  <v-container fluid>
    <!-- Title -->
    <v-row v-if="title">
      <v-col class="ap-dark-gray--text font-weight-bold">
        {{ title }}
      </v-col>
    </v-row>

    <!-- Items -->
    <v-row class="ap-fields-row">
      <v-col
        v-for="(item, index) in items"
        :key="index"
        cols="12"
        :sm="isLastColumn(index) ? lastItemNumberOfColumns : 4"
        :md="isLastColumn(index) ? lastItemNumberOfColumns : 3"
        class="ap-fields-col"
      >
        <div class="text-caption ap-dark-gray--text">
          {{ item.label }}
        </div>
        <div class="font-weight-medium ap-black--text">
          {{ item.value }}
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      required: true,
      // validator(items) {
      //   return !items.find((item) => !item.label || !item.value)
      // },
    },
  },
  computed: {
    lastItemNumberOfColumns() {
      if (this.$vuetify.breakpoint.xs) {
        return 12
      }

      const vuetifyColumnsPerColumn = this.$vuetify.breakpoint.sm ? 4 : 3
      const realNumberOfColumns = this.$vuetify.breakpoint.sm ? 3 : 4
      const columnsInTheLastRow = this.items.length % realNumberOfColumns

      if (!columnsInTheLastRow) {
        return vuetifyColumnsPerColumn
      }

      const numberOfEmptyColumns =
        realNumberOfColumns - (this.items.length % realNumberOfColumns)
      const numberOfVuetifyEmptyColumns =
        numberOfEmptyColumns * vuetifyColumnsPerColumn

      return numberOfVuetifyEmptyColumns + vuetifyColumnsPerColumn
    },
  },
  methods: {
    isLastColumn(index) {
      return this.items.length - 1 === index
    },
  },
}
</script>

<style scoped>
.ap-fields-row {
  border-top: 1px solid var(--v-ap-light-grey-base);
}

.ap-fields-col {
  border-bottom: 1px solid var(--v-ap-light-grey-base);
}
</style>
