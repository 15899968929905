<template>
  <div
    class="dupe-grid-container position-relative ap-white"
    :class="{ 'is-saving': saving }"
    style="overflow: hidden"
  >
    <v-snackbar
      v-model="saved"
      absolute
      centered
      top
      color="ap-green"
      text
      content-class="py-1 px-3"
      min-height="30"
      min-width="100"
      timeout="2000"
    >
      <v-icon small color="ap-green">
        {{ '$mdi-check' }}
      </v-icon>

      <span class="text-caption ml-1">
        {{ snackBarSuccessMessage }}
      </span>
    </v-snackbar>

    <v-snackbar
      v-model="error"
      absolute
      centered
      top
      color="ap-red"
      text
      content-class="py-1 px-3"
      min-height="30"
      min-width="100"
      timeout="2000"
    >
      <v-icon small color="ap-red">
        {{ '$mdi-alert-circle-outline' }}
      </v-icon>

      <span class="text-caption ml-1">
        {{ snackBarErrorMessage }}
      </span>
    </v-snackbar>

    <div class="d-flex">
      <div
        v-if="hasPrevious"
        class="d-flex align-center ap-dark-blue-10 cursor-pointer"
        @click="$emit('previous')"
      >
        <v-icon color="ap-dark-blue">$mdi-chevron-left</v-icon>
      </div>
      <div
        :class="{ 'no-events': saving }"
        style="flex-grow: 1; overflow: auto"
      >
        <DupeGridHeader
          :is-close-button-visible="isInDialog"
          :is-editable="!gridInfo.isStateMerged"
          :title="title"
          :is-loading-notifications="isLoadingNotifications"
          :notifications="notifications"
          :dupe-set-name="
            gridInfo.hasDuplicates && gridInfo.finalRecord.Name.value
          "
          :has-duplicates="gridInfo.hasDuplicates"
          :number-of-duplicates="duplicatesCount"
          :number-of-conflicts="conflictsCount"
          :seconds-to-review="gridInfo.estimatedSecondsToComplete"
          :match-confidence-score="gridInfo.matchConfidenceScore"
          :crm-current-url="crmCurrentUrl"
          :user-email-logged-in="userEmailLoggedIn"
          :dupe-set-id="gridInfo.dupeSetId"
          :entity-name="entityName"
          :entity-id="entityId"
          :is-metadata-visible="isHeaderMetadataVisible"
          class="mx-3"
          @show-only-conflicted="
            generateGrid({ onlyConflictedColumns: $event })
          "
          @message-sent-success="onMessageSentSuccess"
          @message-sent-error="onMessageSentError"
          @close="$emit('close')"
          @tutorial="openTutorialWizard"
        />

        <div v-if="gridInfo.isStateInQueue" class="pa-3">
          <ApAlert
            type="warning"
            title="All done! The merge is being processed by the system..."
            text="Contact your admin if you have any question."
          />

          <ApFieldsGrid title="Final Record:" :items="finalResultArray" />
        </div>

        <ApAlert
          v-else-if="isMergeErrorMessageVisible"
          type="error"
          title="Merge request error"
          text="Merge write has encountered error in the system. Please contact support."
          class="ma-3"
        />

        <template v-else-if="gridInfo.hasDuplicates">
          <DupeGridGrid
            :columns="columns"
            :columns-configs="columnsConfigs"
            :rows="rows"
            :final-data="finalRecord"
            :is-editable="isEditable"
            :is-mandatory-fields-highlighted="isMandatoryFieldsHighlighted"
            @column-moved="columns = $event"
            @cell-selected="onCellSelected"
            @final-cell-changed="onFinalCellChanged"
            @action="onAction"
          />

          <DupeGridFooter
            class="py-2 px-3"
            :details="currentDupeSetDetails"
            :is-merge-visible="isMergeButtonVisible"
            :is-undo-visible="undoStack.length > 0"
            :is-send-to-admin-visible="isSendToAdminButtonVisible"
            :is-send-to-admin-disabled="gridInfo.isStateNotified"
            :is-reprocess-button-visible="isReprocessButtonVisible"
            @undo="undo"
            @merge="onMerge"
            @send-to-admin="onSendToAdmin"
            @reprocess="onReprocess"
          />
        </template>

        <DupeGridNoResults v-else class="mx-auto py-6" />
      </div>
      <div
        v-if="hasNext"
        class="d-flex align-center ap-dark-blue-10 cursor-pointer"
        @click="$emit('next')"
      >
        <v-icon color="ap-dark-blue">$mdi-chevron-right</v-icon>
      </div>
    </div>

    <ApDialog
      v-model="isNotDuplicates"
      :is-close-icon-visible="false"
      title="Not Duplicates"
      content="You have indicated that the records in the set should not be considered duplicates. This set will no longer be presented to you."
      :actions="[
        {
          event: 'undo',
          label: 'Undo',
          color: 'primary',
          outlined: true,
          leftIcon: '$mdi-undo',
        },
        {
          event: 'ok',
          label: 'Ok',
          color: 'primary',
        },
      ]"
      @undo="undo"
      @ok="isNotDuplicates = false"
    />

    <ApDialog
      v-model="isConfirmSendToAdminDialogVisible"
      title="Confirm Send to Admin"
      :content="
        conflictsCount > 0
          ? `This duplicates set has ${conflictsCount} conflicts. Are you sure this set of duplicates is ready to be reviewed by an Admin?`
          : `Are you sure this set of duplicates is ready to be reviwed by an Admin?`
      "
      :actions="confirmActions"
      @cancel="isConfirmSendToAdminDialogVisible = false"
      @confirm="sendToAdmin"
    />

    <ApDialog
      v-model="isConfirmMergeDialogVisible"
      title="Confirm merge"
      :content="
        conflictsCount > 0
          ? `This duplicates set has ${conflictsCount} conflicts. Are you sure you reviewed them all?`
          : `Are you sure you want to merge this set of duplicates?`
      "
      :actions="confirmActions"
      @cancel="isConfirmMergeDialogVisible = false"
      @confirm="merge"
    />

    <ApDialog
      v-if="isReprocessEnabled"
      v-model="isConfirmReprocessDialogVisible"
      title="Confirm reprocess"
      :content="
        conflictsCount > 0
          ? `This duplicates set has ${conflictsCount} conflicts. Are you sure you reviewed them all?`
          : `Are you sure you want to retry merge this set of duplicates?`
      "
      :actions="confirmActions"
      @cancel="isConfirmReprocessDialogVisible = false"
      @confirm="reprocess"
    />
  </div>
</template>

<script>
import DupeGridHeader from './DupeGridHeader'
import DupeGridGrid from './DupeGridGrid'
import DupeGridFooter from './DupeGridFooter'
import DupeGridNoResults from './DupeGridNoResults'
import ApFieldsGrid from '@/components/common/ApFieldsGrid'
import ApAlert from '@/components/common/ApAlert'
import ApDialog from '@/components/common/ApDialog'
import {
  getDupeSetHistory,
  changeDuplicateSet,
  changeDuplicateSetRecordScope,
  sendToAdminDupicateSet,
  mergeDupicateSet,
  reprocessDupicateSet,
} from '@/api/dupe-sets/dupe-sets'
import { formatDate } from '@/utils/dates'
import { cloneDeep } from 'lodash-es'
import { intro, introMergeOptions, introHistoryOptions } from '@/services/intro'

export default {
  components: {
    DupeGridHeader,
    DupeGridGrid,
    DupeGridFooter,
    DupeGridNoResults,
    ApFieldsGrid,
    ApAlert,
    ApDialog,
  },
  props: {
    entityId: {
      type: String,
      required: true,
    },
    entityName: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    isInDialog: {
      type: Boolean,
      deafault: false,
    },
    response: {
      type: Object,
      required: true,
    },
    crmCurrentUrl: {
      type: String,
      required: true,
    },
    userEmailLoggedIn: {
      type: String,
      required: true,
    },
    hasNext: {
      type: Boolean,
      deafault: false,
    },
    hasPrevious: {
      type: Boolean,
      deafault: false,
    },
    isReprocessEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: false,
      snackBarSuccessMessage: null,
      snackBarErrorMessage: null,
      isMandatoryFieldsHighlighted: false,
      isConfirmMergeDialogVisible: false,
      isConfirmReprocessDialogVisible: false,
      isConfirmSendToAdminDialogVisible: false,
      isNotDuplicates: false,
      gridInfo: null,
      isShowOnlyConflictsEnabled: false,
      isLoadingNotifications: false,
      notifications: [],
      columns: [],
      rows: [],
      finalRecord: [],
      columnsConfigs: {
        locked: {
          icon: '$mdi-lock-outline',
          tooltip: 'Locked Column',
          cellClasses: ['ap-dark-gray--text'],
        },
        conflicted: {
          icon: '$mdi-alert-circle',
          iconColor: 'error',
          tooltip: 'Conflicted Column',
          cellClasses: ['ap-red--text', 'font-weight-bold'],
        },
      },
      saving: false,
      saved: false,
      undoStack: [],
      confirmActions: [
        {
          event: 'cancel',
          label: 'Cancel',
          color: 'primary',
          outlined: true,
        },
        {
          event: 'confirm',
          label: 'Confirm',
          color: 'primary',
        },
      ],
    }
  },
  computed: {
    isEditable() {
      if (this.gridInfo.isStateMerged) {
        return false
      } else if (
        this.gridInfo.isActionSendToAdmin &&
        this.gridInfo.isStateNotified
      ) {
        return false
      }
      return true
    },
    isMergeButtonVisible() {
      return (
        !this.gridInfo.isStateMerged &&
        this.gridInfo.isActionMerge &&
        this.duplicatesCount > 1
      )
    },
    isSendToAdminButtonVisible() {
      return !this.gridInfo.isStateMerged && this.gridInfo.isActionSendToAdmin
    },
    isReprocessButtonVisible() {
      return (
        this.isReprocessEnabled &&
        !this.gridInfo.isStateMerged &&
        this.gridInfo.isActionReprocess
      )
    },
    hasMandatoryFields() {
      return this.columns.some((column) => column.isRequired)
    },
    hasLockedColumns() {
      return this.columns.some(
        (column) => !column.isConflicted && column.isLocked
      )
    },
    conflicts() {
      return this.columns.filter(
        (column) => column.isConflicted && !column.isLocked
      )
    },
    conflictsCount() {
      return this.columns.filter(
        (column) => column.isConflicted && !column.isLocked
      ).length
    },
    finalResultArray() {
      return this.columns
        .filter((col) => !col.isPrimary && col.label)
        .map((col) => {
          return {
            label: col.label,
            value: this.finalRecord?.[col.name]?.value,
          }
        })
    },
    currentDupeSetDetails() {
      const items = []

      if (this.gridInfo.isFromCache) {
        items.push({
          icon: '$mdi-flash',
          caption: 'This duplicate set is returned from the cache',
        })
      }

      if (this.gridInfo.dupeSetNumber) {
        items.push({
          label: 'Set ID:',
          value: this.gridInfo.dupeSetNumber,
        })
      }

      if (this.gridInfo.segmentName) {
        items.push({
          label: 'Segment:',
          value: this.gridInfo.segmentName,
        })
      }

      if (this.gridInfo.ownerName) {
        items.push({
          label: 'Owner:',
          value: this.gridInfo.ownerName,
        })
      }

      if (this.gridInfo.assignedTo) {
        items.push({
          label: 'Assigned To:',
          value: this.gridInfo.assignedTo,
        })
      }

      if (this.gridInfo.createdAt) {
        items.push({
          label: 'Created:',
          value: this.gridInfo.createdAt,
        })
      }

      if (this.gridInfo.mergedAt) {
        items.push({
          label: 'Merged:',
          value: this.gridInfo.mergedAt,
        })

        items.push({
          label: 'Merged by:',
          value: this.gridInfo.mergedBy,
        })
      } else if (this.gridInfo.modifiedAt) {
        items.push({
          label: 'Modified:',
          value: this.gridInfo.modifiedAt,
        })
      }

      return items
    },
    duplicatesCount() {
      return this.rows.filter((row) => row.action === 'merge').length
    },
    isMergeErrorMessageVisible() {
      return this.gridInfo.isStateMergeError && !this.isReprocessEnabled
    },
    isHeaderMetadataVisible() {
      return !this.isMergeErrorMessageVisible && !this.gridInfo.isStateInQueue
    },
  },
  async created() {
    this.gridInfo = cloneDeep(this.response)

    if (this.gridInfo.hasDuplicates) {
      this.generateGrid({
        onlyConflictedColumns: false,
      })
      this.getNotifications()
    }
  },
  methods: {
    generateGrid({ onlyConflictedColumns }) {
      this.isShowOnlyConflictsEnabled = onlyConflictedColumns
      this.rows = this.gridInfo.rows

      this.columns = [
        {
          name: 'actions',
          label: '',
          isSticky: true,
        },
      ]

      if (onlyConflictedColumns) {
        this.columns = [
          ...this.columns,
          ...this.gridInfo.columns.filter(
            (column) =>
              column.isPrimary || (column.isConflicted && !column.isLocked)
          ),
        ]
      } else {
        this.columns = [...this.columns, ...this.gridInfo.columns]
      }

      this.finalRecord = {
        actions: { value: 'Final record:' },
        ...this.gridInfo.finalRecord,
      }
    },
    async getNotifications() {
      this.isLoadingNotifications = true
      const history = await getDupeSetHistory(this.gridInfo.dupeSetId)
      this.notifications = this.formatNotifications(
        history.events,
        this.gridInfo.columns
      )
      this.isLoadingNotifications = false
    },
    checkMandatoryFields() {
      this.isMandatoryFieldsHighlighted = this.gridInfo.columns.some(
        (column) =>
          column.isRequired && !this.gridInfo.finalRecord[column.name]?.value
      )

      if (this.isMandatoryFieldsHighlighted) {
        this.snackBarErrorMessage =
          'Please provide values to the mandatory fields.'
        this.error = true
      }
    },
    onMerge() {
      this.checkMandatoryFields()

      if (!this.isMandatoryFieldsHighlighted) {
        this.isConfirmMergeDialogVisible = true
      }
    },
    onReprocess() {
      this.checkMandatoryFields()

      if (!this.isMandatoryFieldsHighlighted) {
        this.isConfirmReprocessDialogVisible = true
      }
    },
    onSendToAdmin() {
      this.checkMandatoryFields()

      if (!this.isMandatoryFieldsHighlighted) {
        this.isConfirmSendToAdminDialogVisible = true
      }
    },
    async sendToAdmin() {
      const messageArray = [
        {
          type: 'header',
          text: { type: 'plain_text', text: 'Request for Merge' },
        },
        { type: 'divider' },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `User: \`${this.userEmailLoggedIn}\``,
          },
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `Duplicate set ID: \`${this.gridInfo.dupeSetId}\``,
          },
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `Record type: \`${this.entityName}\``,
          },
        },
        {
          type: 'section',
          text: { type: 'mrkdwn', text: `Record ID: \`${this.entityId}\`` },
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `Date: \`${formatDate(new Date(), 'MM-dd-yyyy HH:mm:ss')}\``,
          },
        },
      ]

      if (this.crmCurrentUrl) {
        messageArray.push(
          {
            type: 'divider',
          },
          {
            type: 'actions',
            elements: [
              {
                type: 'button',
                text: {
                  type: 'plain_text',
                  text: 'Review merge',
                },
                url: this.crmCurrentUrl,
              },
            ],
          }
        )
      }

      try {
        this.saving = true
        this.isConfirmSendToAdminDialogVisible = false
        await sendToAdminDupicateSet(
          this.gridInfo.dupeSetId,
          JSON.stringify(messageArray)
        )
        this.getNotifications()
        this.snackBarSuccessMessage = 'Sent do Admin'
        this.saved = true
        this.gridInfo.isStateNotified = true
      } catch {
        this.snackBarErrorMessage = 'Something went wrong!'
        this.error = true
      } finally {
        this.saving = false
      }
    },
    async onCellSelected(cell) {
      this.addChangeToUndoStack(cell)

      this.saving = true

      try {
        this.gridInfo = await changeDuplicateSet(this.gridInfo.dupeSetId, {
          column_changes: [
            {
              column_id: cell.columnId,
              selected_row_id: cell.newRowId,
              value: cell.newValue,
            },
          ],
        })

        this.generateGrid({
          onlyConflictedColumns: this.isShowOnlyConflictsEnabled,
        })
        this.getNotifications()

        this.snackBarSuccessMessage = 'Saved'
        this.saved = true
      } catch {
        this.snackBarErrorMessage = 'Something went wrong!'
        this.error = true
      } finally {
        this.saving = false
      }
    },
    async onAction({ item, action }) {
      this.addChangeToUndoStack({
        type: 'action',
        recordId: item.id,
        newAction: action,
        oldAction: item.action,
      })

      this.saving = true

      try {
        this.gridInfo = await changeDuplicateSetRecordScope(
          this.gridInfo.dupeSetId,
          item.id,
          action
        )

        this.generateGrid({
          onlyConflictedColumns: this.isShowOnlyConflictsEnabled,
        })
        this.getNotifications()

        const numberOfDuplicates = this.rows.filter(
          (row) => row.action === 'merge'
        )

        if (numberOfDuplicates.length === 1) {
          this.isNotDuplicates = true
        }

        this.snackBarSuccessMessage = 'Saved'
        this.saved = true
      } catch {
        this.snackBarErrorMessage = 'Something went wrong!'
        this.error = true
      } finally {
        this.saving = false
      }
    },
    async onFinalCellChanged(cell) {
      this.addChangeToUndoStack(cell)

      this.saving = true

      try {
        this.gridInfo = await changeDuplicateSet(this.gridInfo.dupeSetId, {
          column_changes: [
            {
              column_id: cell.columnId,
              value: cell.newValue,
            },
          ],
        })

        this.generateGrid({
          onlyConflictedColumns: this.isShowOnlyConflictsEnabled,
        })
        this.getNotifications()

        this.snackBarSuccessMessage = 'Saved'
        this.saved = true
      } catch {
        this.snackBarErrorMessage = 'Something went wrong!'
        this.error = true
      } finally {
        this.saving = false
      }
    },
    addChangeToUndoStack(action) {
      if (action.type === 'action') {
        this.undoStack.push({
          type: 'action',
          recordId: action.recordId,
          oldAction: action.oldAction,
          newAction: action.newAction,
        })
      } else {
        this.undoStack.push({
          type: 'cell',
          columnId: action.columnId,
          oldRowId: action.oldRowId,
          oldValue: action.oldValue,
        })
      }
    },
    async undo() {
      this.isNotDuplicates = false

      const lastState = this.undoStack.pop()

      this.saving = true

      try {
        if (lastState.type === 'action') {
          this.gridInfo = await changeDuplicateSetRecordScope(
            this.gridInfo.dupeSetId,
            lastState.recordId,
            lastState.oldAction
          )
        } else {
          this.gridInfo = await changeDuplicateSet(this.gridInfo.dupeSetId, {
            column_changes: [
              {
                column_id: lastState.columnId,
                selected_row_id: lastState.oldRowId,
                value: lastState.oldValue,
              },
            ],
          })
        }

        this.generateGrid({
          onlyConflictedColumns: this.isShowOnlyConflictsEnabled,
        })
        this.getNotifications()

        this.snackBarSuccessMessage = 'Saved'
        this.saved = true
      } catch {
        this.snackBarErrorMessage = 'Something went wrong!'
        this.error = true
      } finally {
        this.saving = false
      }
    },
    async merge() {
      try {
        this.saving = true
        this.isConfirmMergeDialogVisible = false
        await mergeDupicateSet(this.gridInfo.dupeSetId)
        this.getNotifications()
        this.gridInfo.isStateInQueue = true
      } catch {
        this.snackBarErrorMessage = 'Something went wrong!'
        this.error = true
      } finally {
        this.saving = false
      }
    },
    async reprocess() {
      try {
        this.saving = true
        this.isConfirmReprocessDialogVisible = false
        await reprocessDupicateSet(this.gridInfo.writeCommandsIds)
        this.getNotifications()
        this.gridInfo.isStateInQueue = true
      } catch {
        this.snackBarErrorMessage = 'Something went wrong!'
        this.error = true
      } finally {
        this.saving = false
      }
    },
    formatNotifications(events, columns) {
      return events.map((event) => {
        const column = columns.find((column) => column.id === event.columnId)

        return {
          ...event,
          field: column?.label,
        }
      })
    },
    onMessageSentSuccess() {
      this.snackBarSuccessMessage = 'Message sent'
      this.saved = true
    },
    onMessageSentError() {
      this.snackBarErrorMessage =
        'Something went wrong! Please try again later.'
      this.error = true
    },
    openTutorialWizard() {
      intro
        .setOptions({
          disableInteraction: true,
          showBullets: false,
          tooltipClass: 'introPopup',
          steps: this.gridInfo.isStateMerged
            ? introHistoryOptions.steps()
            : introMergeOptions.steps({
                hasLockedColumns: this.hasLockedColumns,
                conflictsCount: this.conflictsCount,
                hasMandatoryFields: this.hasMandatoryFields,
                isFromCache: this.gridInfo.isFromCache,
                isMergeButtonVisible: this.isMergeButtonVisible,
                isSendToAdminButtonVisible: this.isSendToAdminButtonVisible,
              }),
        })
        .start()
    },
  },
}
</script>

<style scoped>
.is-saving {
  cursor: wait !important;
}

.no-events {
  pointer-events: none;
}
</style>
