<template>
  <div>
    <div class="d-flex align-center justify-space-between py-2">
      <div>
        <a
          href="https://activeprime.com/"
          target="_blank"
          class="text-decoration-none"
        >
          <ApLogo />
        </a>
      </div>

      <div class="d-flex align-center">
        <template v-if="hasDuplicates">
          <ApMenuNotifications
            id="dupegrid_notifications"
            :notifications="notifications"
            :is-loading="isLoadingNotifications"
          >
            <template #text="{ notification }">
              <div class="font-weight-regular">
                <div v-if="notification.type == 'created'">
                  Duplicate set was created
                </div>

                <div v-else-if="notification.type == 'modified'">
                  <span class="font-weight-medium">
                    {{ notification.user }}
                  </span>
                  modified final rollup value for
                  <span class="font-weight-medium">{{
                    notification.field
                  }}</span
                  >. Changed
                  <span class="font-weight-medium ap-dark-gray--text">
                    "{{ notification.oldValue }}"
                  </span>
                  to
                  <span class="font-weight-medium ap-blue--text">
                    "{{ notification.newValue }}" </span
                  >.
                </div>

                <div v-else-if="notification.type === 'notified_other'">
                  {{ notification.user }} sent a notification to the Admin
                </div>

                <div v-else-if="notification.type === 'assigned'">
                  Duplicate set assigned
                </div>

                <div v-else-if="notification.type === 'write_queued'">
                  Duplicate set queued for merge by
                  <b>{{ notification.user }}</b
                  >.
                </div>

                <div v-else-if="notification.type === 'write_finished'">
                  Duplicate set merge completed successfully.
                </div>

                <div v-else-if="notification.type === 'write_failed'">
                  Duplicate set merge failed
                </div>

                <div v-else-if="notification.type === 'record_added'">
                  Record
                  <span class="font-weight-medium">
                    {{ notification.rowId }}
                  </span>
                  was added to duplicate set
                </div>

                <div v-else-if="notification.type === 'record_deleted'">
                  Record
                  <span class="font-weight-medium">
                    {{ notification.rowId }}
                  </span>
                  was removed from duplicate set
                </div>

                <div v-else-if="notification.type === 'record_modified'">
                  Duplicate record was modified by
                  <b>{{ notification.user }}</b
                  >.
                </div>

                <div
                  v-else-if="notification.type === 'high_mcs_write_finished'"
                >
                  Records were auto-merged in this duplicate set
                </div>

                <div v-else-if="notification.type === 'high_mcs_write_queued'">
                  Records added to queue for auto-merge
                </div>

                <div v-else-if="notification.type === 'high_mcs_write_failed'">
                  Auto-merge failed
                </div>
              </div>
            </template>
          </ApMenuNotifications>
        </template>

        <div id="dupegrid_menu">
          <ApMenu
            icon="$mdi-help-circle-outline"
            :items="moreOptionsMenu"
            @about="isAboutDialogVisible = true"
            @send-message="isSendMessageDialogVisible = true"
            @tutorial="$emit('tutorial')"
          />
        </div>

        <v-btn v-if="isCloseButtonVisible" icon @click="$emit('close')">
          <v-icon> $mdi-close </v-icon>
        </v-btn>

        <ApDialog
          v-model="isAboutDialogVisible"
          title="About ActivePrime"
          content="
            We build CRM tools that help users work more efficiently and effectively.
            If your CRM doesn't work as expected, your adoption rates may be suffering.
            We help customers who use Oracle CRM On Demand,
            Oracle Engagement Cloud (formely Sales Cloud), Oracle Service Cloud,
            Salesforce, and Microsoft Dynamics 365 CRM
          "
          :actions="aboutDialogActions"
        />

        <DupeGridSlackMessage
          v-if="isSendMessageDialogVisible"
          :is-sending-message="isSendingMessage"
          class="px-6"
          @cancel="isSendMessageDialogVisible = false"
          @slack-message="sendSlackMessage"
        />
      </div>
    </div>

    <v-divider />

    <div
      v-if="hasDuplicates && isMetadataVisible"
      class="d-flex flex-wrap align-center justify-space-between py-2"
    >
      <div class="font-weight-medium mr-3" data-tt="dupeSetName">
        {{ title }}: {{ dupeSetName }}
      </div>

      <div class="d-flex align-center flex-wrap">
        <div class="d-flex">
          <DupeGridHeaderMatchConfidenceScore
            v-if="matchConfidenceScore"
            :value="matchConfidenceScore"
            data-tt="mcs"
          />
          <v-divider vertical class="mx-3" />

          <template v-if="isEditable">
            <ApCaptionIcon
              icon="$mdi-file-multiple-outline"
              :label="numberOfDuplicates + ' Duplicates'"
              :tooltip="numberOfDuplicates + '  duplicates found'"
              class="flex-shrink-0"
              data-tt="duplicatesNumber"
            />

            <v-divider vertical class="mx-3" />

            <ApCaptionIcon
              id="dupegrid_conflicts_count"
              icon="$mdi-alert-circle-outline"
              :label="`${numberOfConflicts} Conflicts`"
              :tooltip="`${numberOfConflicts} Conflicts to review`"
              class="flex-shrink-0"
              data-tt="conflictsNumber"
            />

            <v-divider vertical class="mx-3" />

            <ApCaptionIcon
              icon="$mdi-timer-outline"
              :label="secondsToReview + ' Seconds'"
              :tooltip="secondsToReview + ' seconds needed for review'"
              class="flex-shrink-0"
              data-tt="timeToSolve"
            />

            <v-divider vertical class="mx-3" />
          </template>
        </div>

        <div class="d-flex align-center text-caption" data-tt="conflictsFilter">
          <v-simple-checkbox
            v-model="isShowOnlyConflictsEnabled"
            :ripple="false"
            color="ap-dark-blue"
            class="mr-1"
            @input="$emit('show-only-conflicted', $event)"
          />
          <span class="ap-black--text"> Show only conflicted fields</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApLogo from '@/components/common/ApLogo'
import ApMenu from '@/components/common/ApMenu'
import ApMenuNotifications from '@/components/common/ApMenuNotifications'
import ApCaptionIcon from '@/components/common/ApCaptionIcon'
import ApDialog from '@/components/common/ApDialog'
import DupeGridHeaderMatchConfidenceScore from './DupeGridHeaderMatchConfidenceScore'
import logo from '@/assets/images/logo.png'
import DupeGridSlackMessage from './DupeGridSlackMessage'
import { sendDuplicateSetMessage } from '@/api/dupe-sets/dupe-sets'
import { formatDate } from '@/utils/dates'

export default {
  components: {
    ApLogo,
    ApMenu,
    ApMenuNotifications,
    DupeGridHeaderMatchConfidenceScore,
    ApCaptionIcon,
    ApDialog,
    DupeGridSlackMessage,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    dupeSetName: {
      type: String,
      default: '',
    },
    isMetadataVisible: {
      type: Boolean,
      deafult: false,
    },
    hasDuplicates: {
      type: Boolean,
      deafult: false,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
    isCloseButtonVisible: {
      type: Boolean,
      default: false,
    },
    numberOfDuplicates: {
      type: Number,
      default: 0,
    },
    numberOfConflicts: {
      type: Number,
      default: 0,
    },
    secondsToReview: {
      type: Number,
      default: 0,
    },
    matchConfidenceScore: {
      type: String,
      default: '',
    },
    notifications: {
      type: Array,
      default: () => [],
    },
    isLoadingNotifications: {
      type: Boolean,
      default: false,
    },
    dupeSetId: {
      type: String,
      required: true,
    },
    entityId: {
      type: String,
      required: true,
    },
    entityName: {
      type: String,
      required: true,
    },
    crmCurrentUrl: {
      type: String,
      required: true,
    },
    userEmailLoggedIn: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isSendingMessage: false,
      isShowOnlyConflictsEnabled: false,
      isAboutDialogVisible: false,
      isSendMessageDialogVisible: false,
      aboutDialogActions: [
        {
          label: 'Learn more',
          href: 'https://activeprime.com/about-us',
          target: '_blank',
          rightIcon: '$mdi-open-in-new',
          text: true,
        },
        {
          label: 'Privacy',
          href: 'https://activeprime.com/privacy-policy',
          rightIcon: '$mdi-open-in-new',
          target: '_blank',
          text: true,
        },
        {
          label: 'User agreement',
          href: 'https://activeprime.com/terms-of-use',
          rightIcon: '$mdi-open-in-new',
          target: '_blank',
          text: true,
        },
      ],
    }
  },
  computed: {
    moreOptionsMenu() {
      const options = [
        {
          label: 'Send a Message',
          icon: '$mdi-message-text-outline',
          event: 'send-message',
        },
        {
          label: 'How to undo a merge',
          icon: '$mdi-undo',
          href: 'https://www.youtube.com/watch?v=0dWqqLG-KVM',
        },
      ]

      if (this.hasDuplicates) {
        options.push({
          label: 'Tutorial',
          icon: '$mdi-school',
          event: 'tutorial',
        })
      }

      options.push(
        {
          label: 'Support',
          icon: '$mdi-help-circle-outline',
          href: 'https://activeprime.zendesk.com/hc/en-us/requests/new',
        },
        {
          label: 'About ActivePrime',
          img: logo,
          event: 'about',
        },
        {
          label: 'Terms of Use',
          icon: '$mdi-shield-search',
          href: 'https://www.activeprime.net/docs/terms',
        }
      )

      return options
    },
  },
  methods: {
    async sendSlackMessage(message) {
      const messageArray = [
        {
          type: 'header',
          text: { type: 'plain_text', text: 'Message from CleanData' },
        },
        {
          type: 'section',
          text: { type: 'plain_text', text: message.message },
        },
        { type: 'divider' },
        {
          type: 'section',
          text: { type: 'mrkdwn', text: `User: \`${this.userEmailLoggedIn}\`` },
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `Purpose: \`${message.purpose}\``,
          },
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `Priority: \`${message.priority}\``,
          },
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `Duplicate set ID: \`${this.dupeSetId}\``,
          },
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `Record type: \`${this.entityName}\``,
          },
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `Record ID: \`${this.entityId}\``,
          },
        },
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: `Date: \`${formatDate(new Date(), 'MM-dd-yyyy HH:mm:ss')}\``,
          },
        },
      ]

      if (this.crmCurrentUrl) {
        messageArray.push(
          {
            type: 'divider',
          },
          {
            type: 'actions',
            elements: [
              {
                type: 'button',
                text: {
                  type: 'plain_text',
                  text: 'Open record',
                },
                url: this.crmCurrentUrl,
              },
            ],
          }
        )
      }

      try {
        this.isSendingMessage = true

        await sendDuplicateSetMessage(
          this.dupeSetId,
          JSON.stringify(messageArray)
        )

        this.$emit('message-sent-success')
      } catch {
        this.$emit('message-sent-error')
      } finally {
        this.isSendMessageDialogVisible = false
        this.isSendingMessage = false
      }
    },
  },
}
</script>

<style scoped>
::v-deep .v-slide-group__content {
  padding: 0 !important;
}

::v-deep .v-simple-checkbox .v-icon__svg {
  width: 20px;
  height: 20px;
}

::v-deep .v-simple-checkbox .v-input--selection-controls__input {
  margin-right: 0;
}
</style>
