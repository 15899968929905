<template>
  <div>
    <v-menu
      left
      :close-on-content-click="false"
      max-height="calc(100% - 16px)"
      attach=".dupe-grid-container"
    >
      <template #activator="{ on }">
        <v-btn icon :loading="isLoading" v-on="on">
          <v-icon> $mdi-bell-outline </v-icon>
        </v-btn>
      </template>

      <v-card flat min-width="320" max-width="320">
        <v-subheader class="text-subtitle-1 font-weight-black ap-black--text">
          Notifications
        </v-subheader>

        <v-divider />

        <v-card-text v-if="hasNotifications" class="pa-0 ma-0">
          <v-list dense class="py-2">
            <v-list-item
              v-for="(notification, index) in notifications"
              :key="index"
              class="px-4"
            >
              <v-list-item-icon v-if="notification.icon" class="mr-2">
                <v-icon size="22" color="ap-gray">
                  {{ notification.icon }}
                </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <div class="menuItemContent">
                  <slot name="text" :notification="notification">
                    {{ notification.text }}
                  </slot>
                </div>

                <v-list-item-subtitle
                  v-if="notification.time"
                  class="ap-gray--text text-caption"
                >
                  {{ notification.time }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-text v-else> There are no notifications to show </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      deafult: false,
    },
    notifications: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    hasNotifications() {
      return this.notifications.length > 0
    },
  },
}
</script>

<style lang="scss" scoped>
.menuItemContent {
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
}
</style>
